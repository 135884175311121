.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #46467a url('../../public/assets/decorative/bg.svg') no-repeat center right;
  padding: 1rem 2rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  color: #fefefe;

  > div:first-child {
    display: flex;
    align-items: center;

    img {
      height: 40px;
      margin-right: 1rem;
    }

    h1 {
      font-size: 1.5rem;
      margin: 0;
      color: #fefefe;
    }
  }

  nav {
    ul {
      display: flex;
      gap: 2rem;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        a {
          color: #fefefe;
          text-decoration: none;
          font-size: 1.2rem;
          font-weight: 700;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}