/* Contenedor general */
.create-product-container {
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  max-width: 600px;
  margin: 0 auto;
  
  h1 {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  form {
    display: flex;
    flex-direction: column;
    
    div {
      margin-bottom: 1.5rem;
    }
    
    label {
      font-weight: bold;
      margin-bottom: 0.5rem;
      display: block;
      color: #333;
    }
    
    input[type="text"],
    input[type="number"],
    input[type="file"],
    textarea {
      width: 100%;
      padding: 0.5rem;
      border-radius: 4px;
      border: 1px solid #ccc;
      box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.1);
      font-size: 1rem;
    }

    textarea {
      resize: vertical;
      min-height: 100px;
    }

    img {
      max-width: 200px;
      margin-top: 10px;
    }
    
    button {
      background-color: #857abe;
      color: #fefefe;
      border: none;
      padding: 0.75rem;
      border-radius: 4px;
      cursor: pointer;
      font-size: 1rem;
      margin-top: 1rem;
      transition: background-color 0.3s ease;
    }

    button:hover {
      background-color: #9a92c9;
    }
  }

  p {
    margin: 0;
    padding: 0.5rem;
    font-size: 0.9rem;
    border-radius: 4px;
    margin-bottom: 1rem;

    &.text-error {
      background-color: #f8d7da;
      color: #721c24;
    }

    &.text-success {
      background-color: #d4edda;
      color: #155724;
    }
  }
}
