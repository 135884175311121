/* Contenedor general */
.products-container {
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 2rem 10%;

  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  input[type="text"] {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1.5rem;
    font-size: 1rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  /* Estilos para el selector de productos por página */
  .items-per-page {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;

    label {
      margin-right: 0.5rem;
      font-size: 1rem;
      color: #333;
    }

    select {
      padding: 0.5rem;
      border-radius: 4px;
      border: 1px solid #ccc;
      font-size: 1rem;
      background-color: #fff;
      box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      
      &:focus {
        outline: none;
        border-color: #857abe;
      }
    }
  }

  /* Tabla de productos */
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1.5rem;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  th, td {
    padding: 0.75rem 1rem;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    background-color: #857abe;
    color: #fefefe;
  }
  
  th button {
    background: none;
    border: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    padding: 0;
    text-align: left;
  }
  
  th button:hover {
    text-decoration: underline;
  }
  
  tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  /* Botón de acciones */
  button {
    background-color: #857abe;
    color: #fefefe;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  button:hover {
    background-color: #9a92c9;
  }
}

.pagination-controls {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1rem;
}

.pagination-controls button {
  background-color: #b1abde;
  color: #fefefe;
  border: none;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  cursor: pointer;
}

.pagination-controls button.active {
  background-color: #857abe;
  font-weight: bold;
}

.pagination-controls button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination-controls span {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
}
