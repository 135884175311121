.form-card{
  background-color: #575886;
  border-radius: 8px;
  color: #fefefe;
  display: flex;
  height: 500px;
  overflow: hidden;
  width: 700px;

  >div{
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: large;
    gap: 1rem;
    height: 100%;
    overflow: hidden;
    transition: width 0.5s ease, padding 0.5s ease;
    white-space: nowrap;

    &:nth-child(even){
      background: url('../../public/assets/decorative/bg.svg') no-repeat center center;
      display: flex;
      justify-content: center;
      width: 50%;
    }

    &:nth-child(odd){
      display: flex;
      flex-direction: column;
      padding: 2rem 10%;
      width: 100%;

      .input-wrapper{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
      }

      .button-wrapper{
        display: flex;
        gap: 1rem;
        margin-top: auto; 
        width: 100%;

        button{
          width: 100%;
        }
      }
    }

    &:first-of-type{
      background-color: #857abe;
    }

    &:last-of-type{
      background-color: #b1abde;
    }
  }
  
  @media screen and (max-width: 700px){
    width: 100%;

    >div{
      &:nth-child(even){
        width: 0;
        padding: 0;
      }
    }
  }
}

.collapsed{
  width: 0 !important;
  padding: 1rem 0 !important;
}