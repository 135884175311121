/* Contenedor principal */
.product-detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 1rem auto;
  
  p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 1rem;
    text-align: center;
  
    strong {
      color: #333;
    }
  }

  .button-group {
    margin-top: 2rem;
    display: flex;
    gap: 1rem;
    width: 80%;
    
    button {
      padding: 0.5rem 1rem;
      width: 100%;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 1rem;
      transition: background-color 0.3s ease;
    }
  }
}

.product-detail-container p strong {
  display: inline-block;
  width: 100px;
  text-align: left;
}

.product-detail-container p {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-detail-container p:last-of-type {
  max-width: 600px;
  flex-direction: column;
  text-align: justify;
  line-height: 1.5;
}
